<template>
  <main class="container">
    <div class="header h3 mt-2">
          Mantenimiento de Productos
        <button class="btn-2 float-end" @click="showNewModal=true"><i class="fas fa-plus me-2"/> Agregar Nuevo</button>
    </div>

    <!-- ADD NEW MODAL -->
    <b-modal id="modal-1"  size="lg" title="Agregar Producto" class="p-3" centered v-model="showNewModal" hide-footer>
        <form @submit.prevent="postData()" class="mx-3 ">
            <div class="row">
                <div class="col-6">
                    <label class="text-green-2">Código:</label>
                    <input type="text" class="form-control bg-white border-secondary mb-2" required v-model="newItem.id">
                </div>
                <div class="col-6">
                    <label class="text-green-2">Nombre:</label>
                    <input type="text" class="form-control bg-white border-secondary mb-2" required v-model="newItem.name">
                </div>

                <div class="col-6">
                    <label class="text-green-2 mt-2">Almacén:</label>
                    <select class="form-select border-secondary" required v-model="newItem.warehouseId" @change="selectWarehouse()">
                        <option :value="undefined" disabled>* seleccione</option>
                        <option :value="warehouse.id" v-for="warehouse in warehouses" :key="warehouse.id">{{warehouse.name}}</option>
                    </select>            
                </div>
                <div class="col-6">
                    <label class="text-green-2 mt-2">Categoría:</label>
                    <select class="form-select border-secondary" required v-model="newItem.categoryId">
                        <option :value="undefined" disabled>* seleccione</option>
                        <option :value="category.id" v-for="category in categories" :key="category.id">{{category.name}}</option>
                    </select>            
                </div>
                
            
                <div class="col-6">
                    <label class="text-green-2 mt-2">Proveedor:</label>
                    <select class="form-select border-secondary" required v-model="newItem.providerId">
                        <option :value="undefined" disabled>* seleccione</option>
                        <option :value="provider.id" v-for="provider in providers" :key="provider.id">{{provider.name}}</option>
                    </select>            
                </div>
                <div class="col-6">
                    <label class="text-green-2 mt-2">Renglón presupuestario:</label>
                    <select class="form-select border-secondary" required v-model="newItem.accountId">
                        <option :value="undefined" disabled>* seleccione</option>
                        <option :value="account.id" v-for="account in accounts" :key="account.id">{{account.name}}</option>
                    </select>            
                </div>
                <div class="col-6 pt-2">
                    <div class="form-check mt-4">
                        <input class="form-check-input text-green-2" type="checkbox" id="flexCheckDefault" v-model="newItem.fungible" true-value="1" false-value="0" >
                        <!-- <input class="form-check-input text-green-2" type="checkbox" id="flexCheckDefault" v-model="newItem.fungible"> -->
                        <label class="form-check-label text-green-2" for="flexCheckDefault"> Fungible</label>
                    </div>
                </div>
            </div>          

            <button class="mt-3 btn-1 float-end" type="submit">Guardar <i class="fas fa-save ms-2"></i></button>
            <button class="mt-3 btn-3 float-end me-2" type="button" @click="showNewModal=false">Cencelar <i class="fas fa-times ms-2"></i></button>
        </form>
    </b-modal>

    <!-- EDIT MODAL -->
    <b-modal id="modal-2" size="lg" title="Editar Producto" class="p-3" centered v-model="showEditModal" hide-footer>
        <form @submit.prevent="putData()" class="mx-3">
            <div class="row">
                <div class="col-6">
                    <label class="text-green-2">Código:</label>
                    <input type="text" class="form-control bg-white border-secondary mb-2" required v-model="selectedItem.id">
                </div>
                <div class="col-6">
                    <label class="text-green-2">Nombre:</label>
                    <input type="text" class="form-control bg-white border-secondary mb-2" required v-model="selectedItem.name">
                </div>

                <div class="col-6">
                    <label class="text-green-2 mt-2">Almacén:</label>
                    <select class="form-select border-secondary" required v-model="selectedItem.warehouseId" @change="selectWarehouse()">
                        <option :value="undefined" disabled>* seleccione</option>
                        <option :selected="warehouse.id == selectedItem.warehouseId" :value="warehouse.id" v-for="warehouse in warehouses" :key="warehouse.id">{{warehouse.name}}</option>
                    </select>            
                </div>
                <div class="col-6">
                    <label class="text-green-2 mt-2">Categoría:</label>
                    <select class="form-select border-secondary" required v-model="selectedItem.categoryId">
                        <option :value="undefined" disabled>* seleccione</option>
                        <option :selected="category.id == selectedItem.categoryId" :value="category.id" v-for="category in categories" :key="category.id">{{category.name}}</option>
                    </select>            
                </div>
                
            
                <div class="col-6">
                    <label class="text-green-2 mt-2">Proveedor:</label>
                    <select class="form-select border-secondary" required v-model="selectedItem.providerId">
                        <option :value="undefined" disabled>* seleccione</option>
                        <option :value="provider.id" v-for="provider in providers" :key="provider.id">{{provider.name}}</option>
                    </select>            
                </div>
                <div class="col-6">
                    <label class="text-green-2 mt-2">Renglón presupuestario:</label>
                    <select class="form-select border-secondary" required v-model="selectedItem.accountId">
                        <option :value="undefined" disabled>* seleccione</option>
                        <option :value="account.id" v-for="account in accounts" :key="account.id">{{account.name}}</option>
                    </select>            
                </div>
                <div class="col-6 pt-2">
                    <div class="form-check mt-4">
                        <input class="form-check-input text-green-2" type="checkbox" id="flexCheckDefault" v-model="selectedItem.fungible" true-value="true" false-value="false" >
                        <label class="form-check-label text-green-2" for="flexCheckDefault"> Fungible</label>
                    </div>
                </div>
            </div>          
            
            <button class="btn-1 float-end" type="submit">Guardar <i class="fas fa-save ms-2"></i></button>
            <button class="btn-3 float-end me-2" type="button" @click="showEditModal=false">Cencelar <i class="fas fa-times ms-2"></i></button>
        </form>
    </b-modal>

    <!-- DETAILS -->
    <div class="row mt-2 mx-1 " 
         style="min-height:75vh; background:rgba(0,0,0,0.5);">
        
        <div class="col">            
            <b-table
            id="my-table"
            :items="items"
            :per-page="perPage"
            :current-page="currentPage"
            :fields="fields">
            
                 <template #cell(acciones)="data">
                    <div>                        
                        <button @click="showModal(data.item)" class="btn-1 ms-2" style="min-width:40px;;"><i class="fas fa-pen"></i></button>
                        <button @click="deleteItem(data.item)" class="btn-1 ms-2" style="min-width:40px;;"><i class="fas fa-trash"></i></button>
                    </div>
                </template>

            </b-table>

            <b-pagination class="float-end" v-if="items.length > perPage"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
            ></b-pagination>
        </div>
    </div>
  </main>
</template>

<script>
    export default {
        props:["id"],
        computed: {
            rows() {
            return this.items.length
            }
        },
        data(){
            return {
                items:[
                ],
                fields: [
                { key: "id", label:"Código"},
                { key: "name", label:"Nombre"},
                { key: "acciones", tdClass:"text-end", thClass:"text-end pe-5"},          
                ],
                warehouses:[
                ],
                categories:[
                ],
                providers:[
                ],
                accounts:[],
                perPage: 10,
                currentPage: 1,

                newItem:{},
                showNewModal:false,
                selectedItem:{},
                showEditModal:false,
                table: 'products',
                token: ''
            }
        },
        mounted(){
            this.getData()
            this.getWarehouses()
            this.getproviders()
            this.getAccounts()
        },
        methods:{
            async showModal(item){
                await this.getproductCategories(item.warehouseId)
                this.showEditModal=true; 
                this.selectedItem=item
            },
            selectWarehouse(type){
                this.categories = []
                if(type== 'new'){
                    this.newItem.categoryId = 0
                    this.getproductCategories(this.newItem.warehouseId )

                }else{
                    this.selectedItem.categoryId = 0
                    this.getproductCategories(this.selectedItem.warehouseId)
                }
            },
            async getData(){
                try {
                    let res = await this.$store.dispatch('get', {path: this.table + '/getAll'});
                    //console.log(res)
                    if (res.length >0){
                        this.items = res
                    }
                } catch (error) {
                    this.items = []
                    console.error('error', error)
                }
            },
            async getWarehouses(){
                try {
                    let res = await this.$store.dispatch('get', {path: 'warehouses/getAll'});
                    //console.log(res)
                    if (res.length >0){
                        this.warehouses = res
                    }
                } catch (error) {
                    this.warehouses = []
                    console.error('error', error)
                }
            },
            async getproductCategories(id){
                try {
                    let res = await this.$store.dispatch('get', {path: 'productCategories/getAllByWarehouse/' + id});
                    //console.log(res)
                    if (res.length >0){
                        this.categories = res
                    }
                } catch (error) {
                    this.categories = []
                    console.error('error', error)
                }
            },
            async getproviders(){
                try {
                    let res = await this.$store.dispatch('get', {path: 'providers/getAll/'});
                    //console.log(res)
                    if (res.length >0){
                        this.providers = res
                    }
                } catch (error) {
                    this.providers = []
                    console.error('error', error)
                }
            },
            async getAccounts(){
                try {
                    let res = await this.$store.dispatch('get', {path: 'accounts/getAll/'});
                    //console.log(res)
                    if (res.length >0){
                        this.accounts = res
                    }
                } catch (error) {
                    this.accounts = []
                    console.error('error', error)
                }
            },
            async postData(){
                try {
                    this.newItem.status = 1
                    delete(this.newItem.warehouseId)
                    let res = await this.$store.dispatch('post', {path: this.table + '/new', data: this.newItem});
                    //console.log(res)
                    if (res.error){
                        this.$swal({title:"Aviso", text:"Error: " + res.error , icon:"error"})
                    }
                    else{
                        //this.items = res
                        this.showNewModal=false
                        this.getData()
                        this.newItem= {}
                        this.$swal({title:"Aviso", text:"Datos almacenados correctamente", icon:"success"})
                    }
                } catch (error) {
                    this.items = []
                    console.error('error', error)
                }
            },
            async putData(){
                try {
                    let data = Object.assign({} , this.selectedItem) 
                    delete(data.warehouseId)
                    let res = await this.$store.dispatch('put', {path: this.table + '/modify/'+ this.selectedItem.id , data: data});
                    if (res.error){
                        this.$swal({title:"Aviso", text:"Error: " + res.error , icon:"error"})
                    }
                    else{
                        this.showEditModal=false
                        this.getData()
                        this.$swal({title:"Aviso", text:"Datos almacenados correctamente", icon:"success"})
                    }
                } catch (error) {
                    this.items = []
                    console.error('error--->', error)
                }
            },       
            async deleteItem(item){
                const {isConfirmed} = await this.$swal({
                    title:"Confirmar", text:"¿Desea realmente eliminar el registro?", 
                    icon:"question",
                    showCancelButton: true,
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Confirmar',
                    reverseButtons: true

                })
                if(isConfirmed){
                    try {
                        let res = await this.$store.dispatch('delete', {path: this.table + '/delete/' + item.id});
                        if (res.error){
                            this.$swal({title:"Aviso", text:"Error: " + res.error , icon:"error"})
                        }
                        else{
                            this.getData()
                            this.$swal({title:"Aviso", text:"Eliminado correctamente", icon:"success"})
                        }
                    } catch (error) {
                        this.items = []
                        console.error('error--->', error)
                    }                    
                }
            },
        }
    }
</script>

<style>

</style>